import React from "react";
import {
  Box,
  SimpleGrid,
  PseudoBox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Flex,
  Text,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/core";
import PreviewCompatibleImage from "./../components/PreviewCompatibleImage";
import styled from "@emotion/styled";

const Image = styled(PreviewCompatibleImage)`
  position: absolute;
  margin: auto;
  height: 120px;
  width: 120px;
  border-radius: 120px;
`;

function Graduate({ image, text }) {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <Flex alignItems="flex-start">
        <Flex position="relative" overflow="hidden" borderRadius={120}>
          <Image image={image} />

          <PseudoBox
            onClick={() => {
              setOpen(true);
            }}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            _hover={{
              backgroundColor: "rgba(0,0,0,.5)",
              cursor: "zoom-in",
            }}
          />
        </Flex>
        <Flex flex={1} ml={4}>
          <Box>
            <Box h={120} overflow="hidden" position="relative">
              {(text?.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/) || []).map(
                (line) => (
                  <Box>
                    <Text color="#555" fontSize="sm">
                      {line}
                    </Text>
                    <br />
                  </Box>
                )
              )}
              <Box
                position="absolute"
                bottom={0}
                right={0}
                left={0}
                h={10}
                background="linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)"
              />
            </Box>
            {text.length > 0 && (
              <Button
                size="xs"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Lees meer
              </Button>
            )}
          </Box>
        </Flex>
      </Flex>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        isOpen={isOpen}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent py={8}>
          <ModalCloseButton />
          <ModalBody>
            <Flex flex={1}>
              <Flex flex={1}>
                <Box w="100%" h="100%" borderRadius={8} overflow="hidden">
                  <PreviewCompatibleImage image={image} />
                </Box>
              </Flex>
              <Flex flex={1} flexGrow={1} pl={8} flexDirection="column">
                {(text?.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/) || []).map(
                  (line) => (
                    <Box>
                      <Text color="#555" fontSize="sm">
                        {line}
                      </Text>
                      <br />
                    </Box>
                  )
                )}
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function Graduates({ graduates }) {
  return (
    <Box width="full" maxWidth="1280px" mx="auto" px={6}>
      <SimpleGrid columns={[1, 1, 2, 4]} spacing={8} mb={20}>
        {graduates.map(({ image, text }) => (
          <Graduate image={image} text={text} />
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Graduates;
