import React from "react";
import { Box, Text, Button, Flex } from "@chakra-ui/core";

import Container from "../components/Container";
import Banner from "../components/Banner";

const HeadingCarousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const item = items[currentIndex];

  React.useEffect(() => {
    setTimeout(() => {
      setCurrentIndex((prev) => {
        if (prev + 1 === items.length) {
          return 0;
        }

        return prev + 1;
      });
    }, 10000);
  }, [currentIndex]);

  return (
    <Box bg="blue.500" h={[600, 500]} pb={20} pt={10}>
      <Container h="100%">
        <Flex
          direction="column"
          h="100%"
          w={{
            md: "75%",
            lg: "600px",
            xl: "600px",
            xxl: "600px",
          }}
        >
          <Flex direction="column" h="100%">
            <Text fontSize="3xl" color="#fff" fontWeight={700} mb={6}>
              {item.title}
            </Text>
            <Flex mb={10} direction="column" flexGrow={1}>
              <Text fontSize="xl" color="#fff">
                {item.text}
              </Text>
              {!!item.action.text &&
                item.action.text.length > 0 &&
                !!item.action.url &&
                item.action.url.length > 0 && (
                  <Box mt={10}>
                    <Button
                      rightIcon="arrow-forward"
                      variantColor="white"
                      as="a"
                      href={item.action.url}
                      pl={0}
                    >
                      {item.action.text}
                    </Button>
                  </Box>
                )}
            </Flex>
          </Flex>
        </Flex>

        <Container
          display={{
            base: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
            xxl: "block",
          }}
          position="relative"
          mt={-300}
          mr={{
            md: -450,
            lg: -300,
            xl: -150,
            xxl: -200,
          }}
        >
          <Banner.Container>
            <Banner.Image image={item.image} />
          </Banner.Container>
        </Container>
      </Container>
    </Box>
  );
};

export default HeadingCarousel;
