import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import {
  PseudoBox,
  SimpleGrid,
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
} from "@chakra-ui/core";

import Container from "../components/Container";
import Layout from "../components/Layout";
import HeadingCarousel from "../components/HeadingCarousel";
import Graduates from "../components/Graduates";

export const IndexPageTemplate = ({
  title,
  carousel,
  description,
  packages,
  graduates,
  callToAction,
}) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <HeadingCarousel items={carousel} />
    <Box mt={-50} zIndex={6} position="relative">
      <Container>
        <SimpleGrid columns={[1, 1, 2, 3]} spacing={16} mb={20}>
          {(packages || []).map((packet) => {
            return (
              <PseudoBox
                zIndex={1}
                bg="#fff"
                w="100%"
                borderRadius={4}
                overflow="hidden"
                boxShadow="0 4px 16px 13px rgba(184,183,185,0.20)"
                mx={2}
                transition="transform .2s ease-in-out"
                _hover={{
                  transform: "scale(1.06)",
                }}
                textAlign="center"
                margin={0}
                borderRadius={3}
                mt={packet.offer ? -10 : undefined}
              >
                {packet.offer && (
                  <Flex
                    backgroundColor="orange.500"
                    h={10}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text color="white" fontWeight="bold">
                      Meest gekozen
                    </Text>
                  </Flex>
                )}
                <Box p={6} minHeight="450px">
                  <Box pb={6} mb={6} borderBottomWidth={2}>
                    <Heading
                      as="h3"
                      size="xl"
                      color={packet.offer ? "orange.500" : "blue.500"}
                    >
                      {packet.name}
                    </Heading>
                    <Heading
                      as="p"
                      size="lg"
                      color={packet.offer ? "orange.500" : "blue.500"}
                    >
                      {packet.price}
                    </Heading>
                  </Box>
                  <List spacing={3}>
                    {(packet.bullets || []).map((bullet) => (
                      <ListItem>
                        <ListIcon icon="check" />
                        {bullet.text}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </PseudoBox>
            );
          })}
        </SimpleGrid>
        <Flex justify="center" align="center" mb={10}>
          <PseudoBox
            as="a"
            href={callToAction.url}
            pb={4}
            borderBottomWidth={2}
            borderColor="blue.500"
            color="blue.500"
            fontWeight="semibold"
            fontSize="1.75rem"
            transition="transform .2s ease-in-out"
            _hover={{
              transform: "scale(1.06)",
            }}
            textAlign="center"
            mb={8}
          >
            {callToAction.text}
          </PseudoBox>
        </Flex>
      </Container>
      <Graduates graduates={graduates} />
    </Box>
  </>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  packages: PropTypes.arrayOf(PropTypes.object),
  carousel: PropTypes.arrayOf(PropTypes.object),
  callToAction: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        carousel={frontmatter.carousel}
        description={frontmatter.description}
        packages={frontmatter.packages}
        callToAction={frontmatter.callToAction}
        graduates={frontmatter.graduates}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        carousel {
          title
          text
          action {
            text
            url
          }
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        packages {
          name
          price
          offer
          bullets {
            text
          }
        }
        callToAction {
          text
          url
        }
        graduates {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text
        }
      }
    }
  }
`;
